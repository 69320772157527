import React from 'react'
import Head from '../components-en/head'
import Layout from '../components-en/layout'
import '../styles/grid.scss'
import '../styles/index.scss'
import hosGeldin from "../images/hosgeldin.svg"
import iosButton from "../images/en/iosbutton.png"
import androidButton from "../images/en/androidbutton.png"

const InvitePage = ({ location }) => {


return (
<Layout>
<Head title="Join Çember Now" description="Come join us now" />
<div className="row invitation">
    <div className="hero-container">
    <div className="container">
    <div className="col-6">
    
        <h1>Welcome to Çember</h1>    
        <p>Join now, your friends are waiting for you. </p>
   
        <p>Download:</p>
        <div className="downloadbuttons">
            <a href="https://apps.apple.com/us/app/id1532621504" rel="noreferrer"  target="_blank"><img src={iosButton} alt="Download IOS"/></a>
            <a href="https://play.google.com/store/apps/details?id=ist.tio.cember" rel="noreferrer"  target="_blank"><img src={androidButton} alt="Download Android"/></a>
        </div>
        
    </div>
    <div className="col-6">
    <img src={hosGeldin} alt="Welcome" />

    </div>
    </div>
</div>
</div>
    </Layout>

    
)
    
    
}

export default InvitePage